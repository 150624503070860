<template>
  <div>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Add Restaurant'">
          <template v-slot:preview>
            <div class="card card-custom">
              <div class="card-body p-0">
                <!--begin: Wizard-->
                <div
                  class="wizard wizard-2"
                  id="kt_wizard_v2"
                  data-wizard-state="step-first"
                  data-wizard-clickable="true"
                >
                  <!--begin: Wizard Nav -->
                  <div
                    class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10"
                  >
                    <div class="wizard-steps">
                      <div
                        class="wizard-step"
                        data-wizard-type="step"
                        data-wizard-state="current"
                      >
                        <div class="wizard-wrapper">
                          <div class="wizard-icon">
                            <span class="svg-icon svg-icon-2x">
                              <inline-svg
                                src="media/svg/icons/General/User.svg"
                              />
                            </span>
                          </div>
                          <div class="wizard-label">
                            <h3 class="wizard-title">
                              Owner
                            </h3>
                            <div class="wizard-desc">
                              Setup Your Account Details
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="wizard-step" data-wizard-type="step">
                        <div class="wizard-wrapper">
                          <div class="wizard-icon">
                            <span class="svg-icon svg-icon-2x">
                              <inline-svg
                                src="media/svg/icons/Map/Compass.svg"
                              />
                            </span>
                          </div>
                          <div class="wizard-label">
                            <h3 class="wizard-title">
                              Restaurant
                            </h3>
                            <div class="wizard-desc">
                              Setup Your Restaurant Details
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="wizard-step" href="#" data-wizard-type="step">
                        <div class="wizard-wrapper">
                          <div class="wizard-icon">
                            <span class="svg-icon svg-icon-2x">
                              <inline-svg
                                src="media/svg/icons/General/Thunder-move.svg"
                              />
                            </span>
                          </div>
                          <div class="wizard-label">
                            <h3 class="wizard-title">
                              Address
                            </h3>
                            <div class="wizard-desc">
                              Setup Restaurant Address
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Nav -->

                  <!--begin: Wizard Body -->
                  <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
                    <!--begin: Wizard Form-->
                    <div class="row">
                      <div class="offset-xxl-2 col-xxl-8">
                        <!--                        <label class="switch">-->
                        <!--                          New Owner :-->
                        <!--                          <input type="checkbox" v-model="toggleState" :value="toggle" @change="onToggleChange()"/>-->
                        <!--                          <span class="slider round"></span>-->
                        <!--                        </label>-->
                        <form class="form" id="kt_form">
                          <!--begin: Wizard Step 1-->
                          <div
                            class="pb-5"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <h4 class="mb-2 font-weight-bold">
                              Restaurant Owner Details
                            </h4>
                            <label class="switch justify-content-between owner">
                              Existing Owner
                              <div class="text-right">
                                <input
                                  type="checkbox"
                                  v-model="toggleState"
                                  :value="toggle"
                                  @change="onToggleChange()"
                                />
                                <span class="slider round"></span>
                              </div>
                            </label>
                            <hr />
                            <div v-if="hasOwner">
                              <div class="form-group">
                                <div>
                                  <select
                                    @change="test(owner_id)"
                                    v-model="owner_id"
                                    required
                                    name="owner"
                                    class="form-control-role"
                                  >
                                    <option value="">Select Owner</option>
                                    <option
                                      v-for="owner in owners"
                                      :value="owner.owner.uuid"
                                      v-bind:key="owner.owner.uuid"
                                    >
                                      {{ owner.owner.name }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <div class="form-group">
                                <label>Owner Name</label>
                                <input
                                  type="text"
                                  v-model="owner_name"
                                  class="form-control form-control-solid "
                                  name="fname"
                                  placeholder="First Name"
                                  @change="validateOwner()"
                                />
                              </div>
                              <div class="form-group">
                                <label>Email</label>
                                <input
                                  type="email"
                                  v-model="owner_email"
                                  class="form-control form-control-solid "
                                  name="email"
                                  placeholder="Enter Email"
                                  @change="validateOwner()"
                                />
                              </div>
                              <div class="row">
                                <div class="col-xl-6">
                                  <div class="form-group">
                                    <label>Contact</label>
                                    <input
                                      type="number"
                                      min="1"
                                      v-model="owner_contact"
                                      class="form-control form-control-solid "
                                      name="contact"
                                      placeholder="Enter Contact"
                                      @change="validateOwner()"
                                    />
                                  </div>
                                </div>
                                <div class="col-xl-6">
                                  <div class="form-group">
                                    <label>Password</label>
                                    <input
                                      type="password"
                                      v-model="owner_password"
                                      class="form-control form-control-solid"
                                      name="password"
                                      placeholder="Enter Strong Password"
                                      @change="validateOwner()"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--end: Wizard Step 1-->

                          <!--begin: Wizard Step 2-->
                          <div class="pb-5" data-wizard-type="step-content">
                            <h4 class="mb-2 font-weight-bold">
                              Restaurant Details
                            </h4>
                            <hr />
                            <div class="row">
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Name</label>
                                  <input
                                    v-model="restaurant_name"
                                    type="text"
                                    class="form-control form-control-solid"
                                    name="name"
                                    placeholder="Enter Restaurant Name"
                                  />
                                </div>
                              </div>
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Slogan</label>
                                  <input
                                    v-model="restaurant_slogan"
                                    type="text"
                                    class="form-control form-control-solid"
                                    name="slogan"
                                    placeholder="Enter Restaurant Slogan"
                                  />
                                </div>
                              </div>
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Web URL</label>
                                  <input
                                    v-model="restaurant_url"
                                    type="text"
                                    class="form-control form-control-solid"
                                    name="web_url"
                                    placeholder="Enter Web URL"
                                  />
                                </div>
                              </div>
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Contact</label>
                                  <input
                                    v-model="restaurant_contact"
                                    type="number"
                                    min="1"
                                    class="form-control form-control-solid"
                                    name="city"
                                    placeholder="Enter Contact"
                                  />
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div class="form-group">
                                  <label>Description</label>
                                  <textarea
                                    v-model="restaurant_description"
                                    type="text"
                                    class="form-control form-control-solid"
                                    name="description"
                                    placeholder="Enter Description"
                                    rows="4"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--end: Wizard Step 2-->

                          <!--begin: Wizard Step 3-->
                          <div class="pb-5" data-wizard-type="step-content">
                            <h4 class="mb-2 font-weight-bold text-dark">
                              Restaurant Address
                            </h4>
                            <hr />
                            <div class="form-group">
                              <label>Location</label>
                              <vue-google-autocomplete
                                ref="address"
                                id="map"
                                :country="['uk', 'gb']"
                                v-model="restaurant_location"
                                classname="form-control"
                                placeholder="Enter postcode"
                                types="(regions)"
                                v-on:placechanged="getAddressData"
                              >
                              </vue-google-autocomplete>
                            </div>
                            <div class="form-group">
                              <label>Address Line 1</label>
                              <input
                                v-model="restaurant_address1"
                                type="text"
                                class="form-control form-control-solid "
                                name="address_1"
                                placeholder="Enter Address Here"
                              />
                            </div>
                            <div class="form-group">
                              <label>Address Line 2</label>
                              <input
                                v-model="restaurant_address2"
                                type="text"
                                class="form-control form-control-solid "
                                name="address_2"
                                placeholder="Enter Address Here"
                              />
                            </div>
                            <div class="form-group">
                              <label>Country:</label>
                              <input
                                readonly
                                v-model="restaurant_country"
                                type="text"
                                class="form-control form-control-solid "
                                name="Country"
                                placeholder="Enter Country"
                              />
                            </div>
                          </div>
                          <!--end: Wizard Step 3-->

                          <!--begin: Wizard Actions -->
                          <div
                            class="d-flex justify-content-between border-top pt-10"
                          >
                            <div class="mr-2">
                              <button
                                class="btn custom-btn-style"
                                data-wizard-type="action-prev"
                              >
                                Previous
                              </button>
                            </div>
                            <div>
                              <button
                                type="button"
                                v-on:click="AddRestaurant"
                                class="btn custom-btn-style"
                                data-wizard-type="action-submit"
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                class="btn custom-btn-style"
                                data-wizard-type="action-next"
                              >
                                Next Step
                              </button>
                            </div>
                          </div>
                          <!--end: Wizard Actions -->
                        </form>
                      </div>
                      <!--end: Wizard-->
                    </div>
                  </div>
                  <!--end: Wizard Body -->
                </div>
                <!--end: Wizard-->
              </div>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
// import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import axios from "axios";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import helpers from "../../helpers";

export default {
  name: "Wizard-2",
  data() {
    return {
      owner_id: "",
      owner_name: "",
      owner_email: "",
      owner_contact: "",
      owner_password: "",
      restaurant_name: "",
      restaurant_slogan: "",
      restaurant_url: "",
      restaurant_contact: "",
      restaurant_description: "",
      restaurant_address1: "",
      restaurant_address2: "",
      restaurant_country: "",
      restaurant_location: "",
      address: "",
      latitude: "",
      longitude: "",
      toggle: false,
      toggleState: "",
      hasOwner: false,
      owners: [],
      next: true,
      loader: false
    };
  },
  components: {
    KTCodePreview,
    VueGoogleAutocomplete
  },
  methods: {
    // submit: function(e) {
    //   e.preventDefault();
    //   Swal.fire({
    //     title: "",
    //     text: "The application has been successfully submitted!",
    //     icon: "success",
    //     confirmButtonClass: "btn btn-secondary"
    //   });
    // },
    validateOwner() {
      // if(this.owner_name !== "" && this.owner_email !== "" && this.owner_contact !== "" && this.owner_password !== ""){
      // wizardObj.stop();
      //   console.log('Called');
      //   this.next = false;
      // }
    },
    restaurantOwners() {
      axios
        .get(this.$path + "/admin/owner")
        .then(({ data }) => {
          this.owners = data.data;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    test() {},
    AddRestaurant() {
      this.loader = true;
      if (
        this.restaurant_name === "" &&
        this.restaurant_slogan === "" &&
        this.restaurant_url === "" &&
        this.restaurant_contact === "" &&
        this.restaurant_description === "" &&
        this.restaurant_address1 === "" &&
        this.restaurant_address2 === "" &&
        this.restaurant_country === ""
      ) {
        this.loader = false;
        this.$bvToast.toast("Please fill all the fields!", {
          title: "Fields Required",
          variant: "danger",
          solid: true
        });
      }

      let data = {
        owner_type: this.hasOwner,
        owner_uuid: this.owner_id,
        owner_name: this.owner_name,
        owner_email: this.owner_email,
        owner_contact: this.owner_contact,
        owner_password: this.owner_password,
        restaurant_name: this.restaurant_name,
        restaurant_slogan: this.restaurant_slogan,
        restaurant_web: this.restaurant_url,
        restaurant_contact: this.restaurant_contact,
        restaurant_description: this.restaurant_description,
        restaurant_address1: this.restaurant_address1,
        restaurant_address2: this.restaurant_address2,
        restaurant_country: this.restaurant_country,
        restaurant_location_lat: this.latitude,
        restaurant_location_lng: this.longitude
      };

      ApiService.post(this.$path + "/admin/restaurant", data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(() => {
          this.$bvToast.toast("Restaurant Added Successfully!", {
            title: "Success",
            variant: "success",
            solid: true,
            toaster: "b-toaster-top-center"
          });
          this.$router.push({ name: "all_restaurants" });
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    // eslint-disable-next-line no-unused-vars
    getAddressData: function(addressData, placeResultData) {
      this.latitude = addressData.latitude;
      this.longitude = addressData.longitude;
      this.restaurant_country = addressData.country;
      this.address = addressData;
    },
    onToggleChange() {
      this.toggleState ? (this.hasOwner = true) : (this.hasOwner = false);
    }
  },
  mounted() {
    this.restaurantOwners();
    this.$refs.address.focus();
    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v2", {
      startStep: 1, // initial active step number
      clickableSteps: false // allow step clicking
    });

    // Validation before going to next page
    // eslint-disable-next-line no-unused-vars
    wizard.on("beforeNext", function(wizardObj) {
      // validate the form and use below function to stop the wizard's step
      // if (
      //   this.owner_name === "" &&
      //   this.owner_email === "" &&
      //   this.owner_contact === "" &&
      //   this.owner_password === ""
      // ) {
      //   wizardObj.stop();
      // } else {
      //   wizardObj.stop();
      // }
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  }
};
</script>
<style>
.card.card-custom {
  box-shadow: none;
}
.owner {
  color: #fd683e;
}
</style>
